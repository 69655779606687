.navbar {
    background-color: #333;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
}

.navbar-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
}

.navbar-center {
    flex-grow: 1;
    text-align: center;
}

.navbar-user {
    font-size: 1.2rem;
    font-weight: bold;
}

.navbar-links {
    display: flex;
    align-items: center;
}

.navbar-link {
    color: #fff;
    text-decoration: none;
    margin-left: 1rem;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 1rem;
}

.navbar-engaging-text {
    font-size: 1.2rem;
    font-weight: bold;
}

.navbar-search {
    padding: 8px;
    margin-left: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 150px; /* Adjust width as needed */
}

.highlight {
    background-color: yellow;
}
